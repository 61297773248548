.navbar {
  font-size: var(--site-font-size-regular);
  padding: 0;
  box-shadow: 0 0 10px 8px rgba(17, 17, 17, 0.1);
  background-color: $grey-13;
  z-index: 400;
  color: $text-white;

  &.no-border-bottom {
    border-bottom: none !important;
  }

  @include theme(light) {
    background-color: var(--card-background-color);
    background-image: none !important;
    color: $grey-7;
    box-shadow: 0 8px 10px 0 rgba(17, 17, 17, 0.05);
    border-bottom: 1px solid $grey-2;
  }

  @include media-breakpoint-up(md) {
    background-image: radial-gradient(circle at top center, $grey-12, $grey-13 70%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  @include media-breakpoint-up(lg) {
    &.showing-small-usp-bar {
      margin-top: 50px;
    }
  }

  .navbar-side-item-label {
    font-size: 11px;
    background-color: $label-green;
    color: $black;
    padding: 2px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 700;
    margin-left: 0.5rem;
  }

  .mobile-nav-button {
    padding: 1.35em 1.2em;
    color: $text-white;

    @include theme(light) {
      color: var(--site-font-color);
    }
  }

  .nav-container {
    position: relative;

    .google-prompted-login {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    @include media-breakpoint-only(xs) {
      margin: 0;
    }
  }

  .logo-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: auto;
    }

    &.reduced-logo {
      .navbar-brand {
        width: 160px;
      }
    }
  }

  .login-cart-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }

  .top-navbar {
    height: 67px;
    flex-wrap: nowrap;

    &.reduced-height {
      height: 60px;
    }

    @include media-breakpoint-up(lg) {
      height: 84px;
    }

    .autosearch-nav {
      flex-grow: 1;
      width: 100%;
    }
  }

  .navbar-brand {
    padding: 0.2rem 0;
    width: 170px;
    height: 66px;
    margin-right: 0;
    flex-shrink: 0;

    @include media-breakpoint-up(sm) {
      width: 200px;
      height: 65px;
      margin-right: 1rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 0;
      margin: 0 2rem 0 0;
      width: 220px;
      height: 50px;
    }

    .nav-link {
      padding: 0;
      display: flex;
      align-items: center;
      width: auto;
      max-width: 100%;
      height: 100%;
    }
  }

  // CO-BRANDING

  .NavBar__brandContainer {
    display: flex;
    flex-direction: column;

    .NavBar__brandLogo {
      display: block;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .NavBar__brandAssociation {
      display: flex;
      align-items: center;
      font-size: 12px;

      .NavBar__brandAssociation__image {
        margin-left: 0.25rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &--hpomen {
      .NavBar__brandLogo {
        width: 170px !important;

        @include media-breakpoint-up(lg) {
          width: 200px !important;
        }
      }

      .NavBar__brandAssociation {
        margin-top: -10px !important;
        margin-left: 46px !important;

        @include media-breakpoint-up(lg) {
          margin-top: -2px !important;
          margin-left: 55px !important;
        }

        .NavBar__brandAssociation__image {
          width: 64px !important;

          @include media-breakpoint-up(lg) {
            width: 82px !important;
          }
        }
      }
    }

    &--acer {
      .NavBar__brandLogo {
        width: 180px;

        @include media-breakpoint-up(lg) {
          width: 200px;
        }
      }

      .NavBar__brandAssociation {
        margin-top: -8px;
        margin-left: 50px;

        @include media-breakpoint-up(lg) {
          margin-top: -4px;
          margin-left: 55px;
        }

        .NavBar__brandAssociation__image {
          width: 48px;

          @include media-breakpoint-up(lg) {
            width: 56px;
          }
        }
      }
    }

    &--gamespot {
      .NavBar__brandLogo {
        width: 160px;

        @include media-breakpoint-up(sm) {
          width: 180px;
        }

        @include media-breakpoint-up(lg) {
          width: 200px;
        }
      }

      .NavBar__brandAssociation {
        margin-top: -10px;
        margin-left: 43px;

        @include media-breakpoint-up(sm) {
          margin-left: 48px;
        }

        @include media-breakpoint-up(lg) {
          margin-top: -6px;
          margin-left: 55px;
        }

        .NavBar__brandAssociation__image {
          width: 66px;

          @include media-breakpoint-up(lg) {
            width: 76px;
          }
        }
      }
    }

    &--chilliblast {
      .NavBar__brandLogo {
        width: 160px;

        @include media-breakpoint-up(sm) {
          width: 180px;
        }

        @include media-breakpoint-up(lg) {
          width: 200px;
        }
      }

      .NavBar__brandAssociation {
        margin-top: -12px;
        margin-left: 43px;

        @include media-breakpoint-up(sm) {
          margin-left: 48px;
        }

        @include media-breakpoint-up(lg) {
          margin-top: -6px;
          margin-left: 55px;
        }

        .NavBar__brandAssociation__image {
          width: 80px;

          @include media-breakpoint-up(lg) {
            width: 100px;
          }
        }
      }
    }

    &--powercolor {
      .NavBar__brandLogo {
        width: 160px;

        @include media-breakpoint-up(sm) {
          width: 180px;
        }

        @include media-breakpoint-up(lg) {
          width: 200px;
        }
      }

      .NavBar__brandAssociation {
        margin-top: -12px;
        margin-left: 43px;

        @include media-breakpoint-up(sm) {
          margin-left: 48px;
        }

        @include media-breakpoint-up(lg) {
          margin-top: -6px;
          margin-left: 55px;
        }

        a {
          display: flex;
        }

        .NavBar__brandAssociation__image {
          width: 80px;

          @include media-breakpoint-up(lg) {
            width: 100px;
          }
        }
      }
    }

    &--stormforce {
      .NavBar__brandLogo {
        width: 160px;

        @include media-breakpoint-up(sm) {
          width: 180px;
        }

        @include media-breakpoint-up(lg) {
          width: 200px;
        }
      }

      .NavBar__brandAssociation {
        margin-top: -12px;
        margin-left: 43px;

        @include media-breakpoint-up(sm) {
          margin-left: 48px;
        }

        @include media-breakpoint-up(lg) {
          margin-top: -6px;
          margin-left: 55px;
        }

        .NavBar__brandAssociation__image {
          width: 66px;

          @include media-breakpoint-up(lg) {
            width: 88px;
          }
        }
      }
    }

    &--ccl {
      .NavBar__brandLogo {
        width: 160px;

        @include media-breakpoint-up(sm) {
          width: 180px;
        }

        @include media-breakpoint-up(lg) {
          width: 200px;
        }
      }

      .NavBar__brandAssociation {
        margin-top: -14px;
        margin-left: 43px;

        @include media-breakpoint-up(sm) {
          margin-left: 48px;
        }

        @include media-breakpoint-up(lg) {
          margin-top: -8px;
          margin-left: 55px;
        }

        .NavBar__brandAssociation__image {
          width: 50px;

          @include media-breakpoint-up(lg) {
            width: 60px;
          }
        }
      }
    }

    &--insidetech {
      display: flex;
      flex-direction: row;
      align-items: center;

      .NavBar__brandAssociationLarge {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: 80px;
        height: 32px;
        margin-right: 0.75rem;

        @include media-breakpoint-up(sm) {
          width: 120px;
          height: 40px;
          margin-right: 1rem;
        }

        @include media-breakpoint-up(md) {
          width: 100px;
          height: 34px;
          margin-right: 0.75rem;
        }

        @include media-breakpoint-up(xxl) {
          width: 115px;
          height: 39px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .NavBar__brandLogoSupport {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;

        .NavBar__brandSupportText {
          font-size: 12px;
          margin-right: 0.5rem;
        }

        .NavBar__brandLogo {
          display: flex;
          flex-shrink: 0;
          width: 90px;
          height: auto;

          @include media-breakpoint-up(sm) {
            width: 100px;
          }

          @include media-breakpoint-up(lg) {
            width: 110px;
          }
        }
      }
    }

    &--box {
      display: flex;
      flex-direction: row;
      align-items: center;

      .NavBar__brandAssociationLarge {
        width: 46px;
        height: 46px;
        margin-right: 0.5rem;
        flex-shrink: 0;

        @include media-breakpoint-up(sm) {
          width: 50px;
          height: 50px;
          margin-right: 0.75rem;
        }

        @include media-breakpoint-up(lg) {
          width: 55px;
          height: 55px;
        }

        @include media-breakpoint-up(xxl) {
          width: 60px;
          height: 60px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .NavBar__brandLogoSupport {
        .NavBar__brandSupportText {
          font-size: 12px;
          margin-bottom: -5px;

          @include media-breakpoint-up(sm) {
            margin-bottom: -3px;
          }

          @include media-breakpoint-up(lg) {
            margin-bottom: -1px;
          }
        }

        .NavBar__brandLogo {
          width: 110px;

          @include media-breakpoint-up(sm) {
            width: 130px;
          }

          @include media-breakpoint-up(lg) {
            width: 140px;
          }

          @include media-breakpoint-up(xxl) {
            width: 150px;
          }
        }
      }
    }

    &--razer {
      display: flex;
      flex-direction: row;
      align-items: center;

      .NavBar__brandAssociationLarge {
        width: 40px;
        height: 40px;
        margin-right: 0.5rem;
        flex-shrink: 0;

        @include media-breakpoint-up(sm) {
          width: 44px;
          height: 44px;
          margin-right: 0.75rem;
        }

        @include media-breakpoint-up(lg) {
          width: 48px;
          height: 48px;
        }

        @include media-breakpoint-up(xxl) {
          width: 52px;
          height: 52px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .NavBar__brandLogoSupport {
        .NavBar__brandSupportText {
          font-size: 12px;
          margin-bottom: -5px;

          @include media-breakpoint-up(sm) {
            margin-bottom: -3px;
          }

          @include media-breakpoint-up(lg) {
            margin-bottom: -1px;
          }
        }

        .NavBar__brandLogo {
          width: 110px;

          @include media-breakpoint-up(sm) {
            width: 130px;
          }

          @include media-breakpoint-up(lg) {
            width: 140px;
          }

          @include media-breakpoint-up(xxl) {
            width: 150px;
          }
        }
      }
    }
  }

  .nav-top-links {
    font-weight: 400;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;

    .sign-in-btn {
      font-size: var(--site-font-size-regular);
      color: var(--brand-core, $primary-core) !important;
      display: flex !important;
      flex-wrap: wrap;
      justify-content: flex-end;
      min-width: 70px;
      text-align: center;
      font-weight: 400;

      @include theme(light) {
        font-weight: 700;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .nav-account-dropdown {
      color: var(--brand-core, $primary-core);
      max-width: 200px;
      overflow: hidden;

      @include theme(light) {
        font-weight: 700;
      }
    }
  }

  .navbar-nav {
    @include media-breakpoint-up(lg) {
      height: 60px;
    }

    .nav-account-dropdown.dropdown-toggle::after {
      border-top: 0.4em solid;
      border-right: 0.4em solid transparent;
      border-left: 0.4em solid transparent;
      border-bottom: none;
      transition: transform 0.2s, top 0.2s;
    }

    .dropdown-item {
      color: $text-black;
      letter-spacing: 0;
      padding: 0.125rem 1rem;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;

      &:active,
      &:hover {
        background-color: $grey-2;
      }
    }

    .nav-item {
      display: flex;
      height: 100%;
      align-items: center;
      padding: 0 0.5rem;
      cursor: pointer;
      flex-shrink: 0;

      @include media-breakpoint-up(xl) {
        padding: 0 0.625rem;
      }

      @include media-breakpoint-up(xxl) {
        padding: 0 0.75rem;
      }

      .dropdown-toggle {
        padding-right: 0;
        padding-left: 0;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
      }

      .dropdown-menu {
        width: fit-content;
        margin: 0;
      }

      .dropdown-header {
        font-size: 13px;
        padding: 0.125rem 1rem;
        font-weight: 400;
      }

      .dropdown-item {
        color: $text-black;
        text-transform: none;

        &:focus {
          outline: 0;
        }
      }

      .acccount-dropdown-item {
        svg {
          margin-right: 0.5rem;
          color: $grey-5;
          font-size: 0.9rem;
        }
      }

      a {
        // color: $text-white;
        cursor: pointer;
        letter-spacing: 0.4px;
        white-space: nowrap;

        @include theme(light) {
          // color: var(--site-font-color);
        }
      }
    }

    .theme-toggle-button-container {
      padding: 0.125rem 1rem;
    }

    .theme-toogle-container {
      display: flex;
      text-align: center;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-start;
      font-weight: 400;
      color: $text-black;
      border-radius: 0;
      border: 0;
      letter-spacing: 0.4px;
      white-space: nowrap;
      padding: 0.125rem 1rem;

      @include theme(light) {
        color: var(--site-font-color);
      }

      &:hover,
      &:focus {
        text-decoration: none;
        background-color: $grey-2;
      }

      svg {
        color: $grey-5;
        font-size: 0.9rem;
      }

      .icon-container {
        margin-right: 0.7rem;
        margin-left: 0.2rem;
      }

      .text-container {
        white-space: nowrap;
      }

      .switch-container {
        margin-left: 0.5rem;
      }
    }
  }

  .nav-checkout-payment-title {
    display: flex;
    align-items: center;
    margin: 0;

    svg {
      margin-right: 0.75rem;
      font-size: 24px;
    }
  }

  .tablet-search {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    .checkout-title {
      display: flex;
      align-items: center;
      margin-bottom: 0;

      svg {
        font-size: 14px;
        margin-right: 0.5rem;
      }
    }

    .mobile-nav-button {
      margin-right: -0.5rem;

      @include media-breakpoint-up(sm) {
        margin-right: -0.75rem;
      }
    }

    .nav-link {
      font-size: 14px;
      padding: 0.5rem;
      cursor: pointer;
      height: 100%;

      @include media-breakpoint-up(sm) {
        padding: 0.75rem;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 1rem;
      }
    }

    .mobile-cart {
      padding-top: 1.2rem;
      min-width: 50px;
    }

    .autosearch {
      margin: 14px 10px;
      width: 100%;

      input {
        background-color: $white;
        font-weight: 400;
        color: $text-black;
        border-top-left-radius: $border-radius-sm;
        border-bottom-left-radius: $border-radius-sm;
        min-height: 40px;
      }

      .input-group-addon {
        background-color: $white;

        @include media-breakpoint-up(md) {
          background-color: $white;
        }
      }

      button.clear-btn {
        display: none;
      }
    }

    .mobile-search-btn-container {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  .form-inline {
    .input-group-addon {
      background-color: $grey-2;
      color: $grey-5;
      border-color: $grey-2;
    }

    .input-group {
      height: 100%;
    }

    input {
      background-color: $grey-2;
      color: $text-white;
      border-right: 0;
      font-size: 14px;
      border-color: $grey-2;

      @include media-breakpoint-up(md) {
        color: $text-black;
      }

      &::placeholder {
        color: $grey-5;
      }

      &:focus {
        border-color: var(--input-border-color);
        border-right: 0;
      }
    }
  }

  .sign-in-btn {
    padding-right: 0.875rem !important;
    padding-left: 0.875rem !important;
  }

  .cart-items {
    display: flex;
    position: relative;
    background-color: $grey-11;
    transition: all 0.25s;
    border-radius: $border-radius-sm;
    margin-left: 0;
    height: 40px;

    @include theme(light) {
      background-color: $grey-2;
    }

    a,
    button {
      padding: 0 0.5rem !important;

      @include media-breakpoint-up(md) {
        padding: 0 1rem !important;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 0.75rem !important;
      }
    }

    @include media-breakpoint-up(lg) {
      line-height: 1.5;

      &:last-child {
        margin-left: 12px;
      }

      &:hover {
        background-color: lighten($black, 5%);

        @include theme(light) {
          background-color: $grey-3;
        }

        a {
          color: $text-white;

          @include theme(light) {
            color: $text-black;
          }
        }
      }
    }

    .nav-cart-total {
      min-width: 48px;
      margin-left: 4px;
      text-align: right;
      line-height: 1;
      font-size: var(--site-font-size-small);

      @include media-breakpoint-up(xl) {
        min-width: 55px;
      }
    }
  }

  .notification-nav-item {
    margin-left: 12px;
  }

  .nav-badge {
    background-color: var(--brand-core, $primary-core);
    color: var(--brand-font-color, $text-black);
    width: auto;
    min-width: 17px;
    padding: 0 4px;
    height: 17px;
    border-radius: 17px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: normal;
    margin-left: 2px;

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: -6px;
      right: -4px;
      margin-left: 0;
    }
  }
}

.nav-line {
  border-top: 1px solid $grey-13;

  @include theme(light) {
    background-image: linear-gradient(to right, $white 10%, $grey-2 50%, $white 90%);
    border: 0;
    height: 1px;
  }
}

.nav-cart-btn,
.nav-wishlist-btn,
.NotificationsNavButton__button {
  color: $text-white;

  @include theme(light) {
    color: var(--site-font-color);
  }
}

.nav-cart-btn {
  text-decoration: none !important;

  svg {
    font-size: 14px;

    @include media-breakpoint-up(sm) {
      font-size: $font-size-base;
    }
  }
}

.nav-wishlist-btn {
  text-decoration: none !important;
  display: flex;
  align-items: center;
  font-size: $font-size-base;
}

.NotificationsNavButton {
  position: relative;
  display: flex;
  align-items: center;

  .NotificationsNavButton__button {
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    font-size: $font-size-base;
  }
}

.mobile-search-dropdown {
  @include media-breakpoint-up(md) {
    display: none;
  }

  background-color: $grey-10;
  padding: 0.5rem 0.5rem;
  width: 100%;

  @include theme(light) {
    background-color: $white;
  }

  button.clear-btn {
    display: none;
  }

  &.no-background {
    background-color: unset;
    margin-top: -0.5rem;

    @include theme(light) {
      background-color: $white;
    }
  }
}

.nav-more-container {
  position: relative;
}

// SECONDARY NAVBAR

.secondary-navbar {
  display: none;

  @include media-breakpoint-up(lg) {
    display: flex;
    height: 47px;
  }

  .left-links-container,
  .right-links-container {
    display: flex;
    align-items: center;
    overflow-x: clip;
    overflow-y: visible;
    gap: 1.25rem;

    @include media-breakpoint-up(xl) {
      gap: 1.75rem;
    }

    @include media-breakpoint-up(xxl) {
      gap: 2.5rem;
    }
  }

  .secondary-nav-link {
    // color: $grey-2;
    color: $grey-2;
    letter-spacing: 0.4px;
    font-weight: 400;
    white-space: nowrap;

    @include theme(light) {
      font-weight: 700;
      color: var(--site-font-color);
    }

    &:hover {
      color: var(--brand-core, $primary-core);
    }

    &.star-deal-link {
      padding-left: 10px;
    }

    &.black-friday {
      color: $white;
      background-color: $black;
      font-weight: 700;
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.cyber-monday {
      color: $white;
      background-color: #0183ce;
      font-weight: 700;
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .nav-star-deal {
      color: $text-white;
      font-weight: 400;
      letter-spacing: 0.5px;
      padding-left: 22px;
      background: url("data:image/svg+xml;utf8,<svg version='1.0' xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' viewBox='0 0 4710 4710' preserveAspectRatio='xMidYMid meet'><g id='layer1' fill='rgb(38,198,218)' stroke='none'><path d='M3842 4658 c-60 -57 -1150 -1087 -1267 -1199 l-70 -67 -155 216 c-171 238 -189 254 -262 233 -20 -6 -45 -24 -56 -39 -10 -15 -50 -132 -87 -260 -38 -128 -72 -237 -76 -243 -4 -7 -332 136 -876 380 -544 245 -881 391 -900 391 -19 0 -44 -10 -62 -26 -28 -23 -31 -31 -31 -85 l0 -60 134 -142 c73 -78 370 -392 658 -696 l525 -555 -216 -157 c-213 -154 -241 -181 -241 -232 0 -28 33 -83 57 -95 10 -5 126 -42 258 -82 132 -39 241 -73 243 -74 1 -2 -173 -394 -388 -871 -254 -565 -390 -880 -390 -901 0 -20 10 -44 26 -63 23 -28 31 -31 85 -31 l60 0 162 153 c89 85 400 380 691 655 291 276 532 502 536 502 3 0 71 -91 151 -201 79 -111 157 -212 172 -225 41 -34 97 -33 137 3 26 24 40 60 104 276 40 136 75 250 78 252 3 3 395 -170 873 -385 603 -271 878 -390 902 -390 53 0 93 45 93 105 0 25 -5 55 -11 66 -6 11 -300 326 -655 701 -354 374 -644 684 -644 688 0 4 93 74 206 155 113 82 213 160 222 174 27 41 21 96 -14 132 -26 26 -66 41 -270 101 -131 39 -243 74 -248 79 -5 4 157 377 378 869 213 473 389 873 392 889 7 33 -17 85 -45 101 -11 5 -43 10 -71 10 -48 0 -55 -3 -108 -52z m-954 -1560 c7 -7 12 -23 12 -36 0 -12 -65 -168 -145 -345 l-145 -322 73 -75 c228 -234 427 -451 427 -465 0 -30 -20 -55 -44 -55 -13 0 -169 65 -347 145 l-323 146 -50 -49 c-264 -252 -446 -421 -469 -437 -37 -24 -77 -8 -77 30 0 14 65 171 145 348 l145 321 -24 26 c-13 14 -119 126 -236 250 -117 124 -219 235 -227 247 -18 26 -9 59 18 74 16 8 76 -15 345 -136 180 -80 332 -145 339 -143 8 2 129 112 269 246 257 243 282 262 314 230z'/></g></svg>") 0 7px / 20px 20px no-repeat;
      background-position: left top;

      @include theme(light) {
        font-weight: 700;
        color: var(--site-font-color);
      }

      &:hover {
        color: $stardeal-core;
      }
    }
  }

  .store-link-button {
    display: flex;
    height: 47px;

    &:first-of-type {
      .secondary-nav-link {
        padding-left: 0;
      }
    }

    .secondary-nav-link {
      display: flex;
      align-items: center;

      .link-arrow {
        @include theme(light) {
          border-top: 6px solid $grey-13;
        }
      }

      &:hover {
        color: var(--brand-core, $primary-core);
      }
    }

    .secondary-nav-link:hover > .link-arrow {
      border-top: 6px solid var(--brand-core, $primary-core);
    }
  }

  .link-arrow {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid $grey-2;
    margin-left: 3px;

    @include theme(light) {
      border-top: 6px solid $grey-13;
    }

    &:hover {
      border-top: 6px solid var(--brand-core, $primary-core);
    }
  }

  .store-megamenu,
  .bundles-dropdown-container {
    position: absolute;
  }
}

.small-chevron {
  width: 14px !important;
  height: 10px !important;
  font-weight: 400;
  margin-top: 1px;
  color: #333;
}

// EmailSubscribeBar

.EmailSubscribeBar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  .EmailSubscribeBar__copy {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 700;
    font-size: var(--site-font-size-regular);

    svg {
      color: $primary-core;
      display: none;

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }
  }
}

// NavBar

.NavBar {
  &--clear {
    background: transparent !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border-bottom: none !important;

    .nav-line {
      border-color: transparent !important;
      background-image: none;
    }

    .cart-items {
      background-color: rgba($white, 0.1) !important;

      .nav-wishlist-btn,
      .nav-cart-btn {
        color: $font-color-light;
      }
    }

    .nav-top-links {
      .sign-in-btn {
        font-weight: 400 !important;
      }
    }

    .navbar-nav {
      .autosearch {
        input {
          border: 0;
        }
      }

      .input-group {
        input {
          background-color: rgba($white, 0.1);
          color: $white;

          &::placeholder {
            opacity: 0.5;
          }
        }

        .input-group-append {
          background-color: rgba($white, 0.1);
          margin-left: 0;
          border: 0;
        }
      }
    }

    .secondary-navbar {
      .secondary-nav-link {
        color: $font-color-light;
        font-weight: 400;

        .link-arrow {
          border-top-color: $font-color-light !important;
        }

        .nav-star-deal {
          color: $font-color-light;
          font-weight: 400;
        }
      }
    }
  }
}

// HP OMEN Custom Styling

.brand-hpomen {
  .navbar {
    font-size: 12px;

    @include media-breakpoint-up(xl) {
      font-size: 13px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 14px;
    }

    .cart-items {
      .nav-cart-total {
        font-size: 12px;

        @include media-breakpoint-up(xl) {
          font-size: 13px;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 14px;
        }
      }
    }

    .nav-top-links {
      .sign-in-btn {
        font-size: 12px;

        @include media-breakpoint-up(xl) {
          font-size: 13px;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 14px;
        }
      }
    }

    .secondary-navbar {
      .secondary-nav-link {
        .nav-star-deal {
          padding-left: 0;
          background: none;
        }
      }
    }
  }
}
